import classNames from 'classnames';
import React, { useMemo } from 'react';

import {
  DARK_THEME_CLASS,
  LIGHT_THEME_CLASS,
  ThemeContext,
} from './ThemeProvider';
import { useTheme } from './useTheme';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  theme: 'light' | 'dark' | 'user';
}

export const ThemeScope: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  className,
  theme,
  ...attrs
}) => {
  const { isDarkMode, ...props } = useTheme();

  const darkMode = useMemo(() => {
    switch (theme) {
      case 'light':
        return false;
      case 'dark':
        return true;
      case 'user':
        return isDarkMode;
    }
  }, [isDarkMode, theme]);

  return (
    <ThemeContext.Provider
      value={{
        ...props,
        colors: darkMode ? props.darkColors : props.lightColors,
        isDarkMode: darkMode,
      }}
    >
      <div
        className={classNames(
          darkMode ? DARK_THEME_CLASS : LIGHT_THEME_CLASS,
          className
        )}
        {...attrs}
      >
        {children}
      </div>
    </ThemeContext.Provider>
  );
};
