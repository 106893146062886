export const getCookieDomain = (host: string) => {
  if (process.env.NEXT_PUBLIC_SKIP_COOKIE_DOMAIN) return host;
  if (process.env.NEXT_PUBLIC_COOKIE_DOMAIN)
    return process.env.NEXT_PUBLIC_COOKIE_DOMAIN;
  if (host.match(/\.staging\.daily\.co$/)) return '.staging.daily.co';
  if (host.match(/khk-local\.wss\.daily\.co/)) return 'khk-local.wss.daily.co';
  if (host.match(/\.daily\.co$/)) return '.daily.co';
  const parts = host.split('.');
  if (parts.length > 2) {
    parts.shift();
    return `.${parts.join('.')}`;
  }
  return host;
};
