import { systemColors, theme } from './theme';
import type { Theme } from './types';

export const defaultDarkTheme: Theme = {
  ...theme,
  colors: {
    accent: '#1bebb9',
    accentText: systemColors.blueDark,
    background: systemColors.blueDark,
    backgroundAccent: systemColors.blue,
    baseText: systemColors.white,
    border: systemColors.blueLight,
    supportiveText: systemColors.grey,
    system: systemColors,
  },
};
