import { systemColors, theme } from './theme';
import type { Theme } from './types';

export const defaultLightTheme: Theme = {
  ...theme,
  colors: {
    accent: '#1bebb9',
    accentText: systemColors.blueDark,
    background: systemColors.white,
    backgroundAccent: systemColors.greyLightest,
    baseText: systemColors.blueDark,
    border: systemColors.grey,
    supportiveText: systemColors.greyDark,
    system: systemColors,
  },
};
